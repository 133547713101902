import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const TippsFurDenWohnungskauf = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="10 Tipps für den Wohnungskauf" showCalc={false} />
            <Article>
                <p>
                    Gerade, wenn man neu auf dem Immobilienmarkt ist, können die verschiedenen Aspekte, auf die man beim
                    Wohnungskauf im Blick behalten muss, zur Herausforderung werden. Aber keine Sorge: Mit den folgenden
                    10 Tipps kannst du dir einen ersten Überblick darüber verschaffen, worauf du beim Kauf deiner neuen
                    Wohnung achten musst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>1. Lege dein Budget fest</h2>
                <p>
                    Auf Wohnungssuche kannst du natürlich nur gehen, wenn du deine finanziellen Mittel kennst. Hier sind
                    zwei Aspekte entscheidend: Zunächst die Menge an Eigenkapital, die in die Finanzierung deiner neuen
                    Wohnung einbringen kannst. Den neuen, mit Juli 2022 in Kraft getretenen Kreditregeln gemäß, bist du
                    verpflichtet, mindestens 20 % des Kaufpreises mit Eigenmitteln zu bestreiten. Darüber hinaus musst
                    du den Teil deines monatlichen Einkommens bestimmen, über den du frei verfügen kannst - den du also
                    nicht zur Deckung laufender kosten aufzuwenden hast. Dafür führt man eine{" "}
                    <Link
                        to="/artikel/haushaltsrechnung-schritt-fuer-schritt/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Haushaltsrechnung
                    </Link>{" "}
                    durch, in der du sämtliche monatlichen Ausgaben von deinen Einnahmen abziehst.
                </p>
                <hr />

                <h2>2. Definiere deine Anforderungen an die Wohnung</h2>
                <p>
                    Selbstverständlich musst du vor dem Wohnungskauf die Anforderungen an dein neues Zuhause festlegen.
                    Zu klären ist hier etwa die Frage, ob es ein Alt- oder Neubau, wie groß die Wohnung sein und über
                    welche Zimmeraufteilung sie verfügen soll. Zudem solltest du dir Gedanken über die Ausstattung
                    machen: Brauchst du einen Außenbereich wie Balkon, Terrasse oder Garten? Wie sieht es mit dem
                    Stauraum aus: Betreibst du vielleicht eine Sportart, bei der du große Mengen an Equipment benötigst,
                    weshalb ein Kellerabteil unerlässlich ist? Wie sieht es mit einem Aufzug aus, könntest du ohne einen
                    solchen leben? Und ein Parkplatz?
                </p>
                <hr />

                <h2>3. Definiere deine Anforderungen an die Umgebung</h2>
                <p>
                    Mach dir zudem Gedanken über die Infrastrukturen, die du in der unmittelbaren Umgebung deines neuen
                    Zuhauses nicht missen willst: Wie weit darf der nächste Supermarkt, die nächste Apotheke, das
                    nächste Krankenhaus allerhöchstens entfernt sein? Wie weit öffentliche Verkehrsmittel? Sind dir
                    Restaurants, Sportstätten oder andere Freizeiteinrichtungen in der Nähe deiner Wohnung wichtig?
                </p>
                <hr />

                <h2>4. Suche systematisch</h2>
                <p>
                    Hast du erst einmal Budget und Anforderungsprofil festgelegt, dann geht es an die Suche. Verwende
                    dafür am besten Online-Plattformen, auf denen du das Angebot deinen Vorlieben und Möglichkeiten
                    entsprechend eingrenzen kannst. Auch die klassische Immobilienannounce in Printmedien kann, ihrem
                    anachronistischen Touch zum Trotz, bei der Wohnungssuche durchaus hilfreich sein. Weitere Optionen
                    bieten soziale Netzwerke – in virtueller wie in analoger Form –, auf bzw. in denen du dich nach
                    abzugebenden Wohnungen umhören kannst. Vielleicht steht die Wohnung einer Freundin, Bekannten oder
                    Kollegin ja gerade zum Verkauf.
                </p>
                <hr />

                <h2>5. Informiere dich über die Nebenkosten</h2>
                <p>
                    Nicht nur der Preis deines neuen Zuhauses ist beim Wohnungskauf zu bezahlen. Zudem gilt es, eine
                    ganze Reihe von{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>{" "}
                    zu beachten. Dazu zählt die Grunderwerbsteuer von 3,5 %, Anwalts- und Notarkosten in Höhe von 2-3 %,
                    Kosten für die Grundbucheintragung von 1,21 %, Kosten für die Eintragung des Pfandrechts in Höhe von
                    1,2 % des Pfandbetrags sowie ggf. eine Makler:innenprovision von etwa 3 % des Kaufpreises.
                </p>
                <hr />

                <h2>6. Finde die richtige Finanzierung</h2>
                <p>
                    Ein weiterer wichtiger Punkt beim Wohnungskauf ist die Frage der passenden Finanzierung. Welche Rate
                    kannst du dir leisten? Welche Kreditart passt am besten in deine Lebenssituation und welches{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsmodell
                    </Link>{" "}
                    sollte man aktuell wählen. Hier gilt: Gut beraten ist, wer sich gut beraten lässt! Die
                    Finanzierungsexpert:innen von miracl stehen dir für deinen Wohnungskauf gerne zur Verfügung! Mit
                    unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    kannst du dich vorab auch selbst über die Wechselwirkungen zwischen den einzelnen Parametern des
                    Immobilienkaufs informieren.
                </p>
                <hr />

                <h2>7. Informiere dich vorab über den Ablauf eines Immobilienkaufs</h2>
                <p>
                    Lass dich nicht überraschen; überrasche lieber selbst mit deinem Vorwissen zum Ablauf des
                    Immobilienkaufs. Über die verschiedenen Phasen - über das Erstgespräch mit der Notarin, den
                    Kaufvertragsentwurf, die letzte Besichtigung mitsamt dem Übergabeprotokoll, der Abwicklung des
                    Kaufvertrags, den Eintrag ins Grundbuch sowie über die schlussendliche Zahlung von Grunderwerbsteuer
                    und Kaufpreis, auf die die Übergabe folgt - gibt es einiges zu wissen.
                </p>
                <hr />

                <h2>8. Achte auf eine ordnungsgemäße Unterzeichnung des Kaufvertrags</h2>
                <p>
                    Mit der Kaufvertragserstellung sowie der Beglaubigung beauftragst du wahlweise eine Notar:in oder
                    eine auch als Treuhänderin tätige Anwält:in. Nachdem der Kaufvertrag finalisiert wurde, wird dieser
                    an das Finanzamt gesendet, das hierauf die Grunderwerbsteuer einfordert.
                </p>
                <hr />

                <h2>9. Lass dich in das Grundbuch eintragen</h2>
                <p>
                    Die Eintragung ins Grundbuch ist der finale Schritt zur Eigentümerschaft. Nachdem die Notar:in die
                    sogenannte Grundschuldenlöschung durchgeführt hat, trägt sie dich als neue:n Eigentümer:in in das
                    Grundbuch ein. Damit ist die Eigentumsübertragung rechtskräftig.
                </p>
                <hr />

                <h2>10. Eigentümer:innengemeinschaft beachten</h2>
                <p>
                    Mit dem Kauf einer Wohnung wirst du automatisch zum Teil der Eigentümer:innengemeinschaft des
                    betreffenden Wohnhauses. Informiere dich also über die kollektive Nutzungs- und individuelle
                    Sonderrechte, die in der sogenannten Teilungserklärung festgehalten sind. Die
                    Eigentümer:innenversammlung legt meist auch eine Hausordnung fest. Es ist zudem empfehlenswert, sich
                    die Protokolle kürzlich geschlossener Versammlungen aushändigen zu lassen. In diesem kannst du dich
                    über kürzlich ergangene Beschlüsse informieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"tippsFurDenWohnungskauf"}></BreadcrumbList>
            <ArticleStructuredData
                page={"tippsFurDenWohnungskauf"}
                heading={"Was du über die Abgeltungssteuer wissen solltest"}
            />
        </Layout>
    );
};

export default TippsFurDenWohnungskauf;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.tippsfurdenwohnungskauf", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
